<template>
  <v-container fluid fill-heigh>
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-card class="elevation-0 d-flex justify-center align-center" style="background-color: #F0F2F5; height: calc(100vh - 50px);">
          <v-card-text style="width: 1000px; max-width: 100%;">
            <v-row>
              <v-col cols="12" md="6" class="left-column" v-if="!$vuetify.breakpoint.mobile">
              </v-col> 
              <v-col cols="12" md="6" class="right-column" 
                :class="($vuetify.breakpoint.mobile ? 'borda-toda-redonda' : 'borda-direita-redonda')" >
                <!-- Logo do Sistema --> 
                <img src="../../assets/logo-branca.png" alt="Logo do Sistema" class="logo">
                <!-- Texto Descritivo -->
                <p class="description">Sistema para Geração de Negócios <br>no Segmento de Óleo & Gás</p>
                <!-- Div com Borda Arredondada e Sem Cor de Fundo -->
                <v-row>
                  <v-col cols="12" class="login-box">
                    <h1 class="text-center mb-4">Identificação da Conta</h1>
                    <v-fade-transition>
                      <v-progress-linear v-if="processing" color="#efc00f" indeterminate height="10px"/>
                    </v-fade-transition>                  
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12">
                          <v-radio-group v-model="type" row 
                            dense
                            :rules="[
                                    this.$validators.string.required,
                              ]">
                            <v-radio color="#efc00f"
                              value="usuario">
                              <template v-slot:label>
                                <div style="color:white">Usuário</div>
                              </template>
                            </v-radio>
                            <v-radio color="#efc00f"
                              value="fornecedor">
                              <template v-slot:label>
                                <div style="color:white">Fornecedor</div>
                              </template>
                            </v-radio>
                            <v-radio color="#efc00f"
                              label=""
                              value="comprador">
                              <template v-slot:label>
                                <div style="color:white">Comprador</div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>         
                      </v-row>                     
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              solo
                              :rules="[
                                this.$validators.string.required,
                                this.$validators.string.notEmpty,
                              ]"
                              :disabled="processing"
                              placeholder="Informe seu login"
                              v-model="login">
                            <template v-slot:append>
                              <div style="color:white;"><v-icon color="#efc00f">mdi-account</v-icon></div>
                            </template>  
                          </v-text-field>
                        </v-col>
                      </v-row>  
                      <v-row>
                        <v-col cols="12">                           
                          <v-text-field
                              solo
                              :disabled="processing"
                              :placeholder="'Digite sua Senha'"
                              autocomplete="off"
                              v-model="password"
                              @keyup.enter="onEnterClick"
                              :rules="[
                                this.$validators.string.required,
                                this.$validators.string.notEmpty,
                              ]"
                              :type="showPassword ? 'text' : 'password'">
                            <template v-slot:append>
                              <div style="color:white;">
                                <v-icon @click="showPassword = !showPassword" color="#efc00f">{{showPassword ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>         
                      </v-row>                          
                    </v-form>
                    <v-btn class="btn-primary" @click="onEnterClick" :disabled="processing || !valid" block large>Entrar</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" center>
                    <a href="#" class="forgot-password">Esqueceu a senha?</a>
                    <div class="versao">Versão: {{appVersion}} </div>
                  </v-col>
                </v-row>                           
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLogo from "../../components/app/AppLogo";
import {ApiError} from "../../api/core";

export default {
  components: {AppLogo},
  name: "Login",
  data() {
    return {
      appVersion: PACKAGE_VERSION,
      type: 'usuario',
      valid: false,
      login: '',
      password: '',
      showPassword: false,
      processing: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'token', 'isAuthenticated']),
  },
  // watch: {
  //   type: {
  //     deep: true,
  //     handler(newValue) {
  //       if (newValue === 'usuario'){
  //         this.login = 'fabricio';
  //         this.password = 'D044i0gdl';
  //       } else if (newValue === 'fornecedor'){
  //         this.login = 'nara@gestorconsultoria.com.br';
  //         this.password = 'testeFor';
  //       } else {
  //         this.login = 'teste@teste.com.br';
  //         this.password = 'testeCom';
  //       }
  //     }
  //   },
  // },
  methods: {
    ...mapActions('auth', ['doLogin']),
    async onEnterClick() {
      if(!this.$refs.form.validate()) return;
      try {
        this.processing = true;

        await new Promise((resolve) => {
          setTimeout(() => resolve(), 1000);
        })
        
        await this.doLogin({
          type: this.type,
          login: this.login,
          password: this.password
        });

      } catch (err) {
        if (err instanceof ApiError) {
          if (err.message==="duplicado"){
            this.$toast.error(`Mais de um Cliente encontrado com as credencias fornecidas. Entre em contato com a administração!`, {
              timeout: 6000
            })
          } else {
            this.$toast.error(err.message, {
              timeout: 6000
            })
          }
        } else {
          this.$toast.error(`Não foi possível efetuar o login. Entre em contato com a administração!`, {
            timeout: 6000
          })
        }
      } finally {
        this.processing = false;
      }
    },
    rotaCadastrese (){
      this.$router.push({
                    name: 'Cadastre-se'
                  });
    },
  },
  mounted(){
    //this.login = this.type === 'LOGIN_MODES.CLIENT' ? 'fabriciogondim.silva@outlook.com' : 'fabricio';
    //this.password = this.type === LOGIN_MODES.CLIENT ? '01/07/1979' : 'fabricio';
  }
}
</script>

<style scoped lang="scss">
  .left-column {
      background-image: url('../../assets/backgrounds/bg-card-login.jpg');
      background-size: cover;
      background-position: center;
      border-radius: 15px 0 0 15px;
  }
  .right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #167ABC;
    color: #ffffff;
  }
  .borda-direita-redonda {
    border-radius: 0 15px 15px 0;
  }
  .borda-toda-redonda {
    border-radius: 15px;
  }

  .logo {
    width: 200px;
    height: auto;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .login-box {
    padding: 2rem;
    border-radius: 15px;
    width: 100%;
    border: 1px solid #3F91C7;
    background-color: transparent;
  }

  .login-box .form-control {
    background-color: #f8f9fa;
    color: #000000;
  } 

  .login-box .btn-primary {
    background-color: #EFC00F !important;
    color: #ffffff !important;
    border: none;
  }

  .login-box .btn-primary:hover {
    background-color: #2EAB66 !important;
  }

  .forgot-password {
    color: #ffffff;
  }
  .mobile-scroll {
    overflow-y: auto;
  }
  .versao {
    font-size: 0.7rem;
    text-align: center;
  }

/*INPUTS*/
::v-deep .v-input__control .v-input__slot .v-text-field__slot input {
  background-color: #ffffff !important;
}
</style>